import React from 'react'
import { MDBContainer, MDBIframe } from "mdbreact"
import CancelIcon from '@material-ui/icons/Cancel';
import './Home.scss'

function Overlay(props) {
    const handleClose = (e) => {
        e.preventDefault()

        document.querySelector(".overlay").style.width = 0
        document.querySelector('iframe').src = ""

        const h1 = document.querySelector(".overlay-content > h1")
        h1.innerHTML = ""
        h1.style.color = 'transparent'
        h1.style.transition = ""

        window.location.href = `#vid-${e.target.id}`
    }
    return (
        <div className="overlay">
            <div className="overlay-content">
                <div className="closebtn__container"><a href="/#" class="closebtn" onClick={handleClose}><CancelIcon /></a></div>
                <h1>h1</h1>
                <div className="padding-top-40px"></div>
                <div className="video">
                        {/*<iframe title="video" src="" frameBorder="0" allowFullScreen></iframe>*/}
                        <MDBContainer className="text-center">
                            <MDBIframe src=""/>
                        </MDBContainer>
                </div>
            </div>
        </div>
    )
}

export default Overlay
