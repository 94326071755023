import React, { useState, useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import './Home.scss'
import { gridItems } from './constants.js'

const Pagination = (props) => {

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!loading) document.querySelector("#page-1").parentNode.classList.add("current")
        setLoading(true)
    }, [])

    const viewPage = (e) => {
        e.preventDefault()
        props.setCurrentPage(parseInt(e.target.innerText))
        document.querySelectorAll(".pagination__nav li").forEach(el => {
            el.classList.remove("current")
        })
        e.target.parentNode.classList.add("current")
    }

    let count = 1

    return (
        <div className="pagination__nav padding-top-30px">
            <ul>
                { Array(props.pages).fill()
                    .map(el => (<li><a href="/#" id={`page-${count}`} onClick={viewPage}>{count++}</a></li>))
                }
            </ul>
        </div>
    )
}

const GridItem = (props) => {
    let count = 1
    return (
        <div className="grid__item opacity-effect" key={count} onClick={props.handleOnClick}
            id={`vid-${count++}`} data-title={props.item.title} data-yid={props.item.youtube} data-vid={props.item.vimeo} >
            <LazyLoadImage src={props.item.url} alt="" effect="opacity"/>
            <span className="thumb__title">{props.item.title}</span>
        </div>
    )
}

const Grid = (props) => {
    const rows = 3
    const items = gridItems.length
    //console.log(window.screen.width)
    const items_per_page = window.screen.width > 1024 ? 3 * rows : (window.screen.width > 414 ? 2 * rows : 5)
    const pages = Math.ceil(items / items_per_page)

    const allItems = gridItems.map((el, i) => {
        return { ...el, page: Math.floor(i / items_per_page) + 1 }
    })

    const [currentPageItems, setCurrentPageItems] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        setCurrentPageItems(allItems.slice().filter(el => el.page === currentPage))
    }, [currentPage])

    const handleOnClick = (e) => {
        e.preventDefault()
        const overlay = document.querySelector(".overlay")
        overlay.style.width = "100%"

        const h1 = document.querySelector(".overlay-content > h1")
        h1.innerHTML = e.target.dataset.title
        h1.style.color = 'white'
        h1.style.transition = "color 3s ease-in-out"

        const iframe = document.querySelector('iframe')
        iframe.title = e.target.dataset.title
        if (e.target.dataset.yid) iframe.src = `https://www.youtube.com/embed/${e.target.dataset.yid}?autoplay=1&amp;vq=hd1080&amp;byline=0&amp;portrait=0&amp;color=ffffff&amp;amp;autoplay=1`
        if (e.target.dataset.vid) iframe.src = `https://player.vimeo.com/video/${e.target.dataset.vid}?autoplay=1&quality=1080p`
    }

    return (
        <div className="grid__container">
            <div className="grid">
                {
                    currentPageItems.length === 0 ? allItems.slice().filter(el => el.page === 1).map(item => <GridItem {...{ item, handleOnClick }} />)
                     : currentPageItems.map(item => <GridItem {...{ item, handleOnClick }} />)
                }
            </div>
            <Pagination {...{ pages, setCurrentPage }} />
            <div className="arrow__area">
                <div className="arrow__container arrow-second">
                    <a href="#next2">
                        <KeyboardArrowDownIcon className={props.classes.root} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Grid
