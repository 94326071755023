import React from 'react'
import ReactPlayer from 'react-player'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import FacebookIcon from '@material-ui/icons/Facebook'
import './Home.scss'

const VideoPlayer = (props) => {
    const handleClick = () => {
        /*
        const video = document.querySelector('video')
        if (!pause) {
            video.pause()
            setPause(true)
        }
        else {
            video.play()
            setPause(false)
        }
        */
    }

    const handleOnStart = () => {
        /* let background = window.getComputedStyle(document.querySelector('.video-overlay'), ':after').getPropertyValue('background')
        const videoHeight = document.querySelector("video").offsetHeight
        const next__container = document.querySelector(".next__container")
        const next__container2 = document.querySelector("#next2")
        next__container.style.top = videoHeight + "px"
        next__container2.style.top = (videoHeight + next__container.offsetHeight) + "px"
        document.querySelector("footer").style.top = (videoHeight + next__container.offsetHeight + next__container2.offsetHeight) + "px"

        console.log("height", videoHeight)
        console.log("offsetTop", document.querySelector("footer").offsetTop)
        */
    }

    return (
        <div className="video__container" id="video">
            <div className="video-overlay" onClick={handleClick}></div>

            <ReactPlayer url="reel.mp4"
                config={{ file: { attributes: { poster: 'header-video-thumb.jpg' } } }}
                playing={false} loop={true} mute={true} playsinline={true} volume="0"
                onStart={handleOnStart} className="video__wrapper"
            />
            <div className="text-copy">
                <a href="mailto:donamfilm@gmail.com" onClick={(e) => {window.open(e.target.href, '_blank')}}>
                    <MailOutlineIcon />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100054566586353" target="_blank" rel="noopener noreferrer">
                    <FacebookIcon />
                </a>
            </div>
            <div className="arrow__container arrow-first">
                <a href="#next">
                    <KeyboardArrowDownIcon className={props.classes.root} />
                </a>
            </div>
        </div>
    )
}

export default VideoPlayer
