import React from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ImageSlider from "ac-react-simple-image-slider"
import LazyLoad from 'react-lazyload'

function Photo() {
    const obj = (val) => { return { src: `gallery/2/${val}.jpg` } }
    const images = []
    for(let i = 1; i <= 50; i++) {
        images.push(obj(i))
    }
    return (
        <LazyLoad offset={100}>
            <ImageSlider data={images} autoplay={true} showDots={false}
                infinite={true} duration={4} className="left__column" height="inherit" itemStyles={{ maxHeight: 480, objectFit: "contain" }}
                leftArrowComponent={<ArrowBackIosIcon />} rightArrowComponent={<ArrowForwardIosIcon />} />
        </LazyLoad>
    )
}

export default Photo
