import React, { useEffect, useRef } from 'react'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import FacebookIcon from '@material-ui/icons/Facebook';
import RoomIcon from '@material-ui/icons/Room'
import PhoneIcon from '@material-ui/icons/Phone'
import CustomExpandLessIcon from './CustomExpandLessIcon'
import { makeStyles } from '@material-ui/core/styles'

import './Home.scss'

import Overlay from './Overlay'
import VideoPlayer from './VideoPlayer'
import Grid from './Grid'
import Photo from './Photo'

const images = []

for(let i = 1; i <= 27; i++) {
    images.push(`/gallery/1/${i}.jpg`)
}

const styles = theme => ({
    root: {
        color: "white",
        fontSize: 70,
        fontWeight: 400
    }
})

const iconStyles = theme => ({
    root: {
        color: "#E6B34D"
    }
})

function Home() {
    const iconClasses = makeStyles(iconStyles)()
    const classes = makeStyles(styles)()

    const myRef = useRef(null)

    window.addEventListener('scroll', () => {
        const vid = document.querySelector('video')
        if(vid !== null) vid.pause()
    })

    const handleOnScroll = () => {

    }

    useEffect(() => {
    }, [])

    return (
        <div className="home__container" onScroll={handleOnScroll} ref={myRef}>
            
            <Overlay />
            <div className="arrow_dropup__container">
                <a href="#video"><CustomExpandLessIcon /></a>
            </div>
            <VideoPlayer classes={classes} />
            
            <div className="next__container padding-top-40px" id="next">
                <h1>WORK</h1>
                <div className="next__content padding-top-40px">
                    <Grid {...{ images, classes } }/>
                </div>
            </div>
            <div className="next__container padding-top-20px" id="next2">
                <h1>ABOUT ME</h1>
                <div className="next__content padding-top-40px">
                    <div className="next__content__column left">
                        <p>Xin chào, tôi là Đỗ Nam! Tôi đã từng tốt nghiệp khoa đạo diễn điện ảnh, trường đại học Sân Khấu Điện Ảnh TPHCM. Tôi đã thực hiện nhiều phim ngắn và biên kịch phim truyền hình, đạo diễn những phim quảng cáo cho các nhãn hàng. Năm 2018, tôi đã thực hiện bộ phim điện ảnh đầu tay " Ở Đây Có Nắng". Phim được chiếu tại nhiều LHP Quốc Tế và đoạt giải thưởng Best Vietnamfilm tại LHP Quốc tế Sanfrancisco, USA.</p>
                    </div>
                    <div className="next__content__column right">
                        <Photo />
                    </div>
                </div>
            </div>
            
            <footer>
                <div className="contacts">
                    <div>
                        <MailOutlineIcon className={iconClasses.root}/>
                        <a href="mailto:donamfilm@gmail.com" target="_blank" rel="noopener noreferrer">
                            <span>donamfilm@gmail.com</span>
                        </a>
                    </div>
                    <div>
                        <FacebookIcon className={iconClasses.root} />
                        <a href="https://www.facebook.com/profile.php?id=100054566586353" target="_blank" rel="noopener noreferrer">
                            <span>DoNamFilm</span>
                        </a>
                    </div>
                    <div>
                        <RoomIcon className={iconClasses.root} /><span>Saigon</span> <img src="https://lipis.github.io/flag-icon-css/flags/4x3/vn.svg" alt=""/>
                    </div>
                    <div>
                        <PhoneIcon className={iconClasses.root} /><span>091 916 5161</span>
                    </div>
                </div>
                <div className="copyright padding-top-20px">© 2020 Do Nam. All Rights Reserved.</div>
            </footer>
            {/**/}
        </div>
    )
}

export default Home
