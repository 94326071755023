export const gridItems = [
  {
    title: "Khu Rừng Kỳ Diệu | Official Trailer",
    youtube: "_vxH0DywiXE",
    url: "gallery/1/khu_rung_ky_dieu.jpg",
  },
  {
    title: "Ở Đây Có Nắng - Movie",
    youtube: "9aJ26mDMmfg",
    url: "gallery/1/o_day_co_nang.jpg",
  },
  {
    title: "Đường Về Nhà của Hai",
    youtube: "9tp-VyflaPo",
    url: "gallery/1/duong_ve_nha_cua_hai.jpg",
  },
  {
    title: "Tài Ký Food",
    youtube: "RxMDvPFzbs8",
    url: "gallery/1/tai_ky_food.jpg",
  },
  {
    title: "SUNLIGHT",
    youtube: "fn2qeir-A28",
    url: "gallery/1/2.jpg",
  },
  {
    title: "p",
    vimeo: "374631286",
    url: "gallery/1/yhl.jpg",
  },
  {
    title: "Short Film Cowboys Sai Gon",
    vimeo: "416343974",
    url: "gallery/1/4.jpg",
  },
  {
    title: "Short Film 200K",
    youtube: "1Js6yFVFa9Y",
    url: "gallery/1/200k.jpg",
  },
  {
    title: "Short film Giải cứu trùm cuối",
    youtube: "eknZ2C_sqp0",
    url: "gallery/1/6.jpg",
  },
  {
    title: 'TVC "Ingredients for Better Life"',
    youtube: "CBSj_N39H9U",
    url: "gallery/1/ingredients_for_better_life.jpg"
  },
  {
    title: "Short Film Chị Vợ Nóng Bỏng",
    vimeo: "456024836",
    url: "gallery/1/22.jpg",
  },
  {
    title: "TVC sunlight",
    youtube: "IFavAVDImos",
    url: "gallery/1/7.jpg",
  },
  {
    title: "Hứa Minh Đạt vs Quạt hút Panasonic",
    youtube: "h3YnU5lzdqI",
    url: "gallery/1/29.jpg",
  },
  {
    title: "YHL",
    vimeo: "382372575",
    url: "gallery/1/9.jpg",
  },
  {
    title: 'Tiki "Tự Nhiên Cái Tết"',
    youtube: "1Ip-MCPTmP0",
    url: "gallery/1/10.jpg",
  },
  {
    title: "TVC Taky Food",
    vimeo: "295529588",
    url: "gallery/1/8.jpg",
  },
  {
    title: "Comfort",
    youtube: "S-FcTPIJZFI",
    url: "gallery/1/12.jpg",
  },
  {
    title: "Giải Mã Bùa Yêu - Movie",
    youtube: "VHjMEOrA0Rc",
    url: "gallery/1/16.jpg",
  },
  {
    title: "CNG VIETNAM",
    youtube: "vV4rDCsXLk8",
    url: "gallery/1/31.jpg",
  },
  {
    title: "Official Trailer - 2 Mổ",
    youtube: "bBfZOEnaQ1o",
    url: "gallery/1/hai-mo.jpg",
  },
  {
    title: "Xuân Nghị & Bé Hai Đón Xuân Canh Tý 2020",
    youtube: "bZBxEmFl2xI",
    url: "gallery/1/di-sam-do-tet.jpg",
  },
  {
    title: "Viral Gas South",
    vimeo: "724346468",
    url: "gallery/1/viral-gas-south.jpg",
  },
  {
    title: "Viral Football street SCG - Thailand",
    vimeo: "289667705",
    url: "gallery/1/18.jpg",
  },
  {
    title: "Trà Vinh province",
    youtube: "VLwsAq-KSqM",
    url: "gallery/1/30.jpg",
  },
  {
    title: "Lotte Kids FC 2016 - Reality show on VTV3",
    vimeo: "278867094",
    url: "gallery/1/14.jpg",
  },
  {
    title: "Viral Kingsport",
    youtube: "-qOvzwuGVLQ",
    url: "gallery/1/13.jpg",
  },
  {
    title: "TVC larue",
    youtube: "SgX8eHT6uM4",
    url: "gallery/1/15.jpg",
  },
  {
    title: "Bếp Của Mẹ - TV Series on K+",
    vimeo: "278866376",
    url: "gallery/1/11.jpg",
  },
  {
    title: "TVC Super Humic",
    youtube: "CXM85yfhc-w",
    url: "gallery/1/17.jpg",
  },
  {
    title: "MV Lời Chim Đỗ Quyên",
    youtube: "JscDz8kKxjM",
    url: "gallery/1/19.jpg",
  },
  {
    title: "Short film Phía trước là bình minh on HTV3",
    youtube: "uuB9kqqAJto",
    url: "gallery/1/25.jpg",
  },
  {
    title: "Tự Thương Lấy Mình -  Võ Kiều Vân",
    youtube: "iTNLwqEUT-c",
    url: "gallery/1/tu-thuong-lay-minh.jpg",
  },
  {
    title: "MV Tình cờ -  Kỳ Phương Uyên",
    youtube: "j5xmgdN_sFQ",
    url: "gallery/1/21.jpg",
  },
  {
    title: "MV Thấm Thía",
    youtube: "fiUA-GXSNTk",
    url: "gallery/1/26.jpg",
  },
  {
    title: "MV Người Ấy Vẫn Chưa Hiểu",
    youtube: "GTnNCrF9w5M",
    url: "gallery/1/27.jpg",
  },
  {
    title: "MV Yêu Là Phải Thương",
    youtube: "GVfv3-mYFvQ",
    url: "gallery/1/yeu-la-phai-thuong.jpg",
  },
];
